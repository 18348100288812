.product-card {
  background-color: white;
  padding-bottom: 10px;
  padding-right: 10px;

  &.unavailable {
    filter: grayscale(100%) opacity(60%);
  }

  &.list {
    width: 100%;
    padding-right: 0;
  }
  &.grid {
    width: 25%;
    &:nth-child(4) { margin-right: 0; }
    min-width: 170px;
  }

  .product-item {
    border-radius: 3px;

    .product-name {
      font-weight: 800;
      line-height: 1.1;
      margin-bottom: 4px;
    }

    &.grid {
      &:hover{
        box-shadow: 0px 4px 7px rgba(0,0,0,0.2);
      }
      border: solid 1px #d7d7d7;
      height: 100%;
      padding: 2px;
    }

    &.list {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: solid 1px #d7d7d7;
      justify-content: center;
    }

    .product-image {
      &.list {
        margin-right: 10px;
        height: 4vw;
        width: 4vw;
        min-height: 70px;
        min-width: 70px;
      }
    }

    .product-details {
      .note {
        font-size: 12px;
        line-height: 1.15
      }

      .deposit {
        font-size: 12px;
      }

      &.grid {
        padding: 6px;
      }
      &.list {
        display: flex;
        flex-direction: column;
        padding-bottom: 5px;
        width: 100%;
      }
    }
  }
  .product-dropdown{
    width: 80%;
    margin-left: 10%;

    padding: 20px;

    box-shadow: 0px 4px 7px rgba(0,0,0,0.2);
  }
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.33rem;
}

.unit-name {
  font-size: 16px;
}

.product-unit{
  font-size: 16px;
  line-height: 1.2;
}

.product-producer{
  padding-right: 8px;
  font-size: 14px;
  color: #a0a0a0;
}

.producer-details {
  line-height: 16px;
}

.producer-city-state{
  font-size: 12px;
  color: #a0a0a0;
}

.product-description{
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

#product-overlay{
  .product-description{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #nav-and-photo{
    display: flex;

    #product-overlay-image{
      text-align: center;
      flex: 6;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 768) and (max-width: 1200px){
  .product-card {
    &.grid {
      width: 33.33%;
      &:nth-child(3) { margin-right: 0; }
    }
  }
}

@media only screen and (max-width: 767px){

  .product-card {
    &.grid {
      width: 50%;
      &:nth-child(2) { margin-right: 0; }
      margin: 0px;
    }
    .product-image {
      &.grid { max-height: 500px; }
    }
  }

  .product-dropdown{
    background-color: white;
    width: 90%;

    margin-left: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .product-producer{
    font-size: 14px;
  }
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.image-container {
  &.temporarily-unavailable::after {
    position: absolute;
    left: 5%;
    width: 90%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  &.temporarily-unavailable.grid::after {
    border-radius: 5px;
  }
  &.temporarily-unavailable.list::after {
    border-radius: 2px;
  }
  &.grid::after{
    top: 36%;
    height: 28%;
    font-size: 20px;
    line-height: 20px;
  }
  &.list::after {
    top: 30%;
    height: 40%;
    font-size: 9px;
    line-height: 9px;
  }
  &.preorder::after {
    content: "PREORDER";
    background-color: rgba(40, 167, 69, 0.6);
    color: white;
  }
  &.temporary::after {
    content: "TEMPORARILY UNAVAILABLE";
    background-color: rgba(255, 193, 7, 0.60);
    color: white;
  }

  &.discount::after {
    content: "SALE";
    position: absolute;
    top: 5%;
    right: 0%;
    padding: 10px;
    height: 15%;
    display: flex;
    line-height: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 0, 0, 0.80);
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  &.discount.list::after {
    top: 5%;
    height: 10%;
    padding: 8px 4px;
    font-size: 9px;
    line-height: 9px;
  }
}
