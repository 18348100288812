$click-to-edit: aliceblue;
$waiting: grey;
$success: green;
$failed: red;
$sending: burlywood;

.price-width {
  max-width: 3.75rem;
}

input {
  &.indicate-success {
    @extend .alert-success;
    &:disabled {
      @extend .alert-success;
    }
  }
}

select {
  &.indicate-success {
    background-color: #d4edda !important;
    border-color: $green !important;
  }
}

.remote-price-input {
  @extend .border;
  @extend .border-secondary;
  @extend .pr-1;
  @extend .price-width;
  @extend .text-right;
  @extend .rounded-left;
  &:not(.indicate-success) {
    background-color: $click-to-edit;
  }
}

.react-input {
  border: 2px solid $click-to-edit;
  border-radius: 3px;
  outline: none;
  background-color: $click-to-edit;

  &.waiting {
    &:focus {
      border-color: $waiting;
    }
  }

  &.failed {
    border-color: $failed;
  }

  &.success {
    border-color: $success;
  }

  &.sending {
    border-color: $sending;
  }
}
