@media screen {
  #stickers { width: 10in }
}
@media print {
  .sticker {
    border: 1px solid #f5f5f5 !important;
  }
}
#stickers, .stickers {
  font-family: Arial;
  position: relative;
  .page {
    position: relative;
    page-break-after: always;
  }
  .sticker {
    overflow: hidden;
    padding: 20px;
    height: 100%;
  }
  .top-row {
    height: 15%;
  }
  .middle-row {
    height: 40%;
  }
  .bottom-row {
    height: 45%;
  }

  .header, .footer {
    height: 20px;
  }

  .sticker-spacer {
    &:nth-child(even) { padding-right: 10px }
    &:nth-child(odd) { padding-left: 10px }
    height: 248px;
    width: 50%;
    display: inline-block;
  }

  .sub {
    vertical-align: sub;
  }
}
