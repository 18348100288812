html {
  font-size: 14px;
}

.invoice-number {
  div {
    right: 0px;
    position: absolute;
    font-weight: bold;
    top: 5px;
  }
  position: relative;
}

.text-nowrap {
  white-space: nowrap !important;
}

.small { 
  font-size: .875em;
}

.invoice {
  thead { display: table-header-group; }
  tfoot {display: table-row-group; }
  tr { page-break-inside: avoid }
  td, th { 
    padding: 0px;
    border: none;
    line-height: 18px;

    &.border-bottom {
      border-bottom: 1px solid #dee2e6;
    }

    > div {
      padding: 1px 2px;
    }
  }

  .strong {
    font-weight: bold;
  }

  .line-item-note {
    position: relative;
    top: -5px;
  }

  .table-row {
    width: 100%;
  }

  .table-column-72 {
    display: inline-block;
    width: 72%;
    vertical-align: top;
  }

  .table-column-80 {
    display: inline-block;
    width: 80%;
    vertical-align: top;
  }

  .table-column-90 {
    display: inline-block;
    width: 90%;
    vertical-align: top;
  }

  .table-column-8 {
    display: inline-block;
    width: 8%;
    vertical-align: top;
  }
  .table-column-10 {
    display: inline-block;
    width: 10%;
    vertical-align: top;
  }
}


.top-spacer {
  margin-top: 20px;
}

#email-preview {
  max-width: 600px;
  .invoice {
    margin: 40px auto;
    text-align: left;
    width: 90%;
  }
  .invoice td {
    padding: 5px 2px;
  }
  .invoice .invoice-items {
    width: 100%;
  }
  .invoice .invoice-items td {
    border-top: #eee 1px solid;
  }
  .invoice .invoice-items .total td {
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
    font-weight: 700;
  }
}