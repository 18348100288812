@import "catalog_cart.scss";
@import "catalog_products.scss";

.toastify {
  margin-left: 200px;
  z-index: 1050;
}

.drop-shadow-hover:hover{
  animation: focus-shadow 0.3s 1 forwards;
  animation-fill-mode: forwards;
}

@keyframes focus-shadow{
  0% { box-shadow: 0px 0px 0px rgba(0,0,0,0);}
  100% { box-shadow: 0px 4px 7px rgba(0,0,0,0.2); }
}

@keyframes fade-in{
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@media only screen and (min-width: 575px){
  #fuzzy-placeholder {
    height: 27px;
  }
}

#catalog {
  top: -10px;
  a { color: black; }

  #search-input {
    height: 44px;
  }

  #modal-mask{
    position: fixed;
    overflow: auto;
    width: 100%;
    height: 100vh;
    top: 0px;
    right: 0px;
    background-color: rgba(0,0,0,0.5);
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;

    justify-content: center;
    z-index: 1050;
  }

  .modal-popout{
    width: 600px;
    background-color: #f7f7f7;
    border-radius: 4px;
    align-self: flex-start;
    padding: 10px;
  }

  #category-toggles {
    display: block;
    flex-wrap: wrap;
    @extend .mb-2;
  }
  .category-clicker {
    font-size: 1.25rem;
    white-space: nowrap;
    margin-bottom: 4px;
    border-bottom: white 2px solid;
    cursor: pointer;
    &.active {
      font-weight: bold;
      border-bottom: black 2px solid;
    }
  }

  .collection-clicker {
    font-size: 1.25rem;
    white-space: nowrap;
    margin-bottom: 4px;
    border-bottom: white 2px solid;
    cursor: pointer;
    &.active {
      font-weight: bold;
      border-bottom: black 2px solid;
    }
  }
  .subcollection-clicker {
    font-size: 1rem;
    white-space: nowrap;
    border-bottom: white 2px solid;
    margin-bottom: 3px;
    cursor: pointer;
    &.active {
      font-weight: bold;
      border-bottom: black 2px solid;
    }
  }


  #catalog-header {
    align-items: center;
    #logo {
      img { max-height: 120px }
    }
    #layout-options {
      @extend .mt-2;
    }
  }

  #filters {
    margin-top: 2px;
    @extend .px-2;
  }
  #catalog-body {
    // min-height:1000px;
    display: flex;

    #sidebar {
      #toggles {
        margin-top: 15px;
      }
      #producer-select {
        width: 100%;
        height: 25px;
      }
    }

    #products-body {
      width: 75%;
      #no-results {
        margin-top: 20px;
        text-align: center;
        color: #909090;
      }
      #products-header {
        margin-bottom: 10px;
      }
      #products {
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
      }
    }
  }
}

.view-options{
  flex: 1;
  width: 100%;
  display: flex;
}

.search-notifier{
  width: 100%;
  @extend .my-1;
  min-height: 21px;
  text-align: center;
  color: #858585;
}

#catalog-page-tabs{
  width: 100%;

  display: flex;

  align-items: center;
  justify-content: center;

  margin-top: 40px;
}

.catalog-page-tab{
  margin-left: 5px;
}

button.catalog-page-tab-button{
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: #e7e7e7;
}

@media only screen and (max-width: 575px){
  #catalog {
    .modal-popout{
      width: 90vw;
    }

    #modal-mask{
      width: 100%;
      left: 0px;
    }
  }
}

@media only screen and (max-width: 575px){
  .toastify {
    margin-left: 0px;
  }
}
