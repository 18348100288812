#shopping-cart-toggle{
  padding: 0px 13px;
  border-radius: 20%;
  z-index: 91;
}

.popout-shopping-cart-container{
  position: fixed;
  overflow: auto;
  z-index: 1000;

  right: 0px;
  top: 49px;
  bottom: 0px;
  width: 300px;

  background-color: rgb(255,255,255);

  box-shadow:0px 10px 20px rgba(0,0,0,0.26);
}

.popout-shopping-cart-header{
  font-weight: bold;
  font-size: 17px;
}

.popout-shopping-cart-products{
  overflow-y: scroll;
}

.cart-product-info-container{
  width: 100%;
  line-height: 1.3;
}

.cart-product-container{
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;

  border-bottom-width: 1px;
  border-bottom-color: #e7e7e7;

  background-color: white;
}

.cart-product-container:hover{
  background-color: #e7e7e7;
}

@media only screen and (max-width: 767px){
  .popout-shopping-cart-container{
    width: 100%;
  }
}
