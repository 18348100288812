@font-face {
  font-family: 'SignikaNegative';
  src: url('../fonts/SignikaNegative-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.custom-qty {
  @extend .text-nowrap;
  display: inline-flex;
  align-items: baseline;
  .decimal {
    font-size: 0.7em; /* Smaller size */
    margin-left: 0.05em; /* Small gap to the right of the whole number */
  }
}

.modal-huge {
  max-width: 95vw;
}

.map-pin {
  background-color: rgba(256,256,256,0.7);
  color: black;
  border-radius: 50% 50% 50% 50%;
  border: black 1px solid;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  transform: rotate(-45deg);
  &.map-start {
    border-radius: 0;
    background-color: green;
    color: black;
  }
  &.map-end {
    border-radius: 0;
    background-color: red;
    color: black;
  }
}


@font-face {
  font-family: 'SignikaNegative';
  src: url('../fonts/SignikaNegative-Semibold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

html {
  font-size: 14px;
  body {
    font-family: 'SignikaNegative';
  }
}
.dt-search {
  @extend .d-print-none;
}
.text-black {
  color: black;
}

.product-unit-availability {
  width: 60px;
}

.calendar-day {
  flex-basis: calc(14.2857% - 2px);
  margin: 1px; /* Add margin between items for spacing */
  text-align: center;
  padding: 2px;
}

.selectize-dropdown-content{
  max-height: 400px !important;
}

@media only screen and (max-width: 575px){
  #nav-name {
    max-width: 90px;
  }
}

.order-list-item {
  width: 100%;
  .status { width: 30px }
  .expected-on { width: 80px }
  .item-count { width: 45px }
  .total { width: 75px }
}

.w-30px {
  width: 30px;
}

.flatpickr-day.selected {
  @extend .alert-success;
}
.flatpickr-day {
  margin: 2px;
}
.flatpickr-day.route-available {
  @extend .border-success;
  border-radius: 0;
}

.pricing-type-label {
  width: 160px;
}

.nav-tabs .nav-link.active {
  font-weight: bold;
}

.dropdown-menu {
  z-index: 1050;
}

.dropdown-count-width {
  width: 24px;
}

.popover.popover-order {
  font-family: 'SignikaNegative';
  width: 300px;
  .popover-body {
    table {
      margin-bottom: 2em;
    }
    padding: 0;
    height: 300px;
    overflow-y: scroll;
  }
}

.text-strike {
  text-decoration: line-through;
}

.huge-shadow {
  box-shadow: 0 1rem 2rem 4rem rgb(0 0 0 / 50%) !important
}

.popover.popover-order-items {
  border: 0;
  font-family: 'SignikaNegative';
  max-width: 500px;
  .popover-header {
    font-weight: bold;
  }
  .popover-body {
    table {
      margin-bottom: 2em;
    }
    padding: 0;
    width: 500px;
    height: 500px;
    overflow-y: scroll;
  }
}

.popover.popover-product-unit  {
  border: 0;
  font-family: 'SignikaNegative';
  max-width: 350px;
  .popover-header {
    font-weight: bold;
  }
  .popover-body {
    table {
      margin-bottom: 2em;
    }
    padding: 0;
    width: 350px;
    height: 200px;
    overflow-y: scroll;
  }
}

.inventory-forecast.popover {
  max-width: 400px;
}

.checkbox-input-toggler .fa {
  width: 22px;
}

#main {
  padding-top: 60px;
  min-height: 80vh;
  .toast-wrapper {
    .toast {
      margin: 1rem;
      min-width: 200px;
    }
    position: fixed;
    overflow: auto;
    top: 50px;
    right: 5px;
    z-index: 1100;
    .toast.show {
      @extend .shadow;
    }
  }
}

.list-group-under-nav {
  >.list-group-item {
    &:first-child {
      @extend .border-top-0;
    }
  }
}

.dtfh-floatingparent {
  @extend .d-print-none;
}

.dataTables_filter {
  @extend .d-print-none;
}
table.dataTable > tbody > tr {
  &.alert-info {
    @extend .alert-info;
  }
  &.alert-success {
    @extend .alert-success;
  }
}

table.table-middle {
  td {
    vertical-align: middle;
  }
}

table.table-800 {
  min-width: 800px;
}
table.table-1000 {
  min-width: 1000px;
}


.modal-body {
  overflow-x: scroll;
}

.text-gray {
  color: $gray-400 !important
}

.pointered {
  cursor: pointer;
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#profile {
  &.karma { font-family: 'Karma', serif; }
  &.mina { font-family: 'Mina', sans-serif; }
  &.montserrat { font-family: 'Montserrat', sans-serif; }
}

.profile-column {
  @extend .col-md-6;
  @extend .p-4;
  p {
    @extend .lead;
  }
}

.badge-blue {
  color: #fff;
  background-color: #264653;
}

.badge-green {
  color: #fff;
  background-color: #2A9D8F;
}

.badge-yellow {
  color: #fff;
  background-color: #E9C46A;
}

.badge-orange {
  color: #fff;
  background-color: #F4A261;
}

.badge-red {
  color: #fff;
  background-color: #E76F51;
}

.form-control.is-valid {
  background-image: none
}

.border-2 {
  border-width: 2px !important;
}

button.nav-link {
  color: $blue;
  background-color: transparent;
}

.nav-icon-set {
  text-align: center;
  display: inline-block;
  min-width: 42px;
}

.profile-section {
  @extend .row;
  @extend .bg-light;
  @extend .m-3;
  @extend .border;
  @extend .rounded
}

input {
  &.integer-2 {
    width: 2rem !important;
  }
  &.sig-2 {
    width: 4rem !important;
  }
  &.sig-3 {
    width: 5rem !important;
  }
  &.sig-4 {
    width: 6rem !important;
  }
}

.input-group-text {
  &.narrow {
    padding: 0.375rem 0.375rem;
  }
}

.btn-xs {
  padding: 0.1rem 0.25rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

ul.alert li {
  margin-left: 20px;
}

@media print {
  #main {
    padding-top: 0 !important;
  }
  nav {
    display: none !important;
  }
  canvas {
    width: 100% !important;
  }
  .no-print-border {
    border: 0;
  }

  table {
    width: 100% !important;
  }
}

.table-sticky-header {
  border-collapse: separate;
  border-spacing: 0px;
  thead {
    z-index: 2; 
    background-color: white;
  }
}

.forecast-up-arrow {
  height: 5px;
  position: relative;
  top: -12px;
}

.forecast-down-arrow {
  height: 5px;
  position: relative;
  top: -12px
}

.user-color-0 {
  background-color: #264653;
  color: white;
}

.user-color-1 {
  background-color: #2A9D8F;
  color: white;
}

.user-color-2 {
  background-color: #E9C46A;
  color: white;
}

.user-color-3 {
  background-color: #F4A261;
  color: white;
}

.user-color-4 {
  background-color: #E76F51;
  color: white;
}

.form-control.flatpickr-input {
  background-color: white;
}

.scrollable-overlay:after {
  content: ' ';
  position: absolute;
  left: 0;
  height: 2em;
  bottom: 0;
  width: 100%;
  background:linear-gradient(
    to bottom,
    rgba(255,255,255,0),
    rgba(255,255,255,1)
  );
  z-index: 1;
}
