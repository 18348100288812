#sales-order-edit, #purchase-order-edit {
  .order-row {
    @extend .list-group-item;
    @extend .border-left-0;
    @extend .border-right-0;
    @extend .d-flex;
    @extend .px-1;
    @extend .no-gutters;
    &.save-failed {
      @extend .list-group-item-danger;
    }
    &.save-pending {
      @extend .list-group-item-warning;
    }
    &.save-successful {
      @extend .list-group-item-success;
    }
  }

  .toggle-more-column {
    @extend .d-none;
    @extend .d-md-block;
    @extend .pr-2;
  }

  #new-item-row {
    @extend .position-sticky;
    top: 48px;
    background-color: white;
    z-index: 10;
  }

  #item-header-row {
    @extend .position-sticky;
    top: 120px;
    background-color: white;
    z-index: 2;
  }

  .unit-price-column {
    @extend .text-right;
    @extend .d-none;
    @extend .d-md-block;
    min-width: 100px;
    max-width: 100px;
  }

  .subtotal-price-column {
    @extend .text-right;
    min-width: 100px;
    max-width: 100px;
  }

  .quantity-column {
    @extend .pr-2;
    min-width: 65px;
    width: 65px;
  }

  .item-column {
    @extend .pr-2;
    @extend .w-100;
  }

  .pricing-column {
    max-width: 200px;
    min-width: 100px;
  }
}