@import "bootstrap_custom";
@import "~bootstrap/scss/bootstrap";
@import 'react-toastify/dist/ReactToastify.css';

@import "common";
@import "catalog";
@import "seller";
@import "order";
@import "stickers";
@import "email_and_pdf";

.custom-control-label::after {
  cursor: pointer;
}
